export default {
  // DigitalOcean: [
  //   {
  //     title: 'Understanding Syntax and Code Structure in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/understanding-syntax-and-code-structure-in-javascript',
  //   },
  //   {
  //     title: 'How to Write Comments in JavaScript',
  //     path: 'https://www.digitalocean.com/community/tutorials/how-to-write-comments-in-javascript',
  //   },
  //   {
  //     title: 'How to Work with Strings in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-work-with-strings-in-javascript',
  //   },
  //   {
  //     title: 'How To Index, Split, and Manipulate Strings in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-work-with-strings-in-javascript',
  //   },
  //   {
  //     title: 'How To Do Math in JavaScript with Operators',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-do-math-in-javascript-with-operators',
  //   },
  //   {
  //     title: 'Understanding Arrays in JavaScript',
  //     path: 'https://www.digitalocean.com/community/tutorials/understanding-arrays-in-javascript',
  //   },
  //   {
  //     title: 'How To Use Array Methods in JavaScript: Mutator Methods',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-use-array-methods-in-javascript-mutator-methods',
  //   },
  //   {
  //     title: 'How To Use Array Methods in JavaScript: Accessor Methods',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-use-array-methods-in-javascript-accessor-methods',
  //   },
  //   {
  //     title: 'How To Use Array Methods in JavaScript: Iteration Methods',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-use-array-methods-in-javascript-iteration-methods',
  //   },
  //   {
  //     title: 'Understanding Objects in JavaScript',
  //     path: 'https://www.digitalocean.com/community/tutorials/understanding-objects-in-javascript',
  //   },
  //   {
  //     title: 'How to Use Object Methods in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-use-object-methods-in-javascript',
  //   },
  //   {
  //     title: 'How To Write Conditional Statements in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-write-conditional-statements-in-javascript',
  //   },
  //   {
  //     title: 'How To Use the Switch Statement in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-use-the-switch-statement-in-javascript',
  //   },
  //   {
  //     title: 'Using While and Do...While Loops in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/using-while-and-do-while-loops-in-javascript',
  //   },
  //   {
  //     title: 'Understanding For Loops in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-construct-for-loops-in-javascript',
  //   },
  //   {
  //     title: 'Understanding Functions in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-define-functions-in-javascript',
  //   },
  //   {
  //     title: 'Understanding Date and Time in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/understanding-date-and-time-in-javascript',
  //   },
  //   {
  //     title: 'Understanding Prototypes and Inheritance in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/understanding-prototypes-and-inheritance-in-javascript',
  //   },
  //   {
  //     title: 'Understanding Classes in JavaScript',
  //     path: 'https://www.digitalocean.com/community/tutorials/understanding-classes-in-javascript',
  //   },
  //   {
  //     title: 'Understanding Variables, Scope, and Hoisting in JavaScript',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/understanding-variables-scope-hoisting-in-javascript',
  //   },
  //   {
  //     title: 'How To Upload a File to Object Storage with Node.js',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-upload-a-file-to-object-storage-with-node-js',
  //   },
  //   {
  //     title: 'Introduction to the DOM',
  //     path: 'https://www.digitalocean.com/community/tutorials/introduction-to-the-dom',
  //   },
  //   {
  //     title: 'Understanding the DOM Tree and Nodes',
  //     path: 'https://www.digitalocean.com/community/tutorials/understanding-the-dom-tree-and-nodes',
  //   },
  //   {
  //     title: 'How To Access Elements in the DOM',
  //     path: 'https://www.digitalocean.com/community/tutorials/how-to-access-elements-in-the-dom',
  //   },
  //   {
  //     title: 'How To Traverse the DOM',
  //     path: 'https://www.digitalocean.com/community/tutorials/how-to-traverse-the-dom',
  //   },
  //   {
  //     title: 'How To Make Changes to the DOM',
  //     path: 'https://www.digitalocean.com/community/tutorials/how-to-make-changes-to-the-dom',
  //   },
  //   {
  //     title: 'How To Modify Attributes, Classes, and Styles in the DOM',
  //     path:
  //       'https://www.digitalocean.com/community/tutorials/how-to-modify-attributes-classes-and-styles-in-the-dom',
  //   },
  //   {
  //     title: 'Understanding Events in JavaScript',
  //     path: 'https://www.digitalocean.com/community/tutorials/understanding-events-in-javascript',
  //   },
  //   {
  //     title: 'Understanding This, Bind, Call, and Apply in JavaScript',
  //     path: 'https://www.digitalocean.com/community/conceptual_articles/understanding-this-bind-call-and-apply-in-javascript',
  //   },
  // ],
  // LogRocket: [
  //   {
  //     title: 'Setting up a RESTful API with Node.js and PostgreSQL',
  //     path: 'https://blog.logrocket.com/setting-up-a-restful-api-with-node-js-and-postgresql-d96d6fc892d8/',
  //   }
  // ],
  // Codrops: [
  //   {
  //     title: 'An Introduction to CSS Shapes',
  //     path: 'https://tympanus.net/codrops/2018/11/29/an-introduction-to-css-shapes',
  //   },
  // ],
  // Progress: [
  //   {
  //     title: 'ES6 Syntax, Features, and Additions: A Reference Guide',
  //     path: 'https://www.telerik.com/blogs/es6-syntax-features-and-additions',
  //   },
  // ],
  // 'Envato Tuts+': [
  //   {
  //     title: 'Code Your First API With Node.js and Express: Understanding REST APIs',
  //     path:
  //       'https://code.tutsplus.com/tutorials/code-your-first-api-with-nodejs-and-express-understanding-rest-apis--cms-31697',
  //   },
  //   {
  //     title: 'Code Your First API With Node.js and Express: Set Up the Server',
  //     path:
  //       'https://code.tutsplus.com/tutorials/code-your-first-api-with-nodejs-and-express-set-up-the-server--cms-31698',
  //   },
  //   {
  //     title: 'Code Your First API With Node.js and Express: Connect a Database',
  //     path:
  //       'https://code.tutsplus.com/tutorials/code-your-first-api-with-nodejs-and-express-connect-a-database--cms-31699',
  //   },
  // ],
  // SitePoint: [
  //   {
  //     title: 'JavaScript Operators, Conditionals & Functions',
  //     path: 'https://www.sitepoint.com/javascript-operators-conditionals-functions',
  //   },
  //   {
  //     title: 'A Beginner’s Guide to JavaScript Variables and Datatypes',
  //     path: 'https://www.sitepoint.com/beginners-guide-javascript-variables-and-datatypes',
  //   },
  // ],
  // Gatsby: [
  //   {
  //     title: 'The End of an Era: Migrating from WordPress to Gatsby',
  //     path: 'https://www.gatsbyjs.org/blog/2019-03-21-migrating-from-wordpress-to-gatsby/',
  //   },
  //   {
  //     title: 'Roll Your Own Comment System',
  //     path: 'https://www.gatsbyjs.org/blog/2019-08-27-roll-your-own-comment-system/',
  //   },
  // ],
}
